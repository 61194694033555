
export default {
  color: {
    primary: {
      light: '#3FA1EA',
      main: '#0062AA',
      dark: '#124583',
      contrastText: '#FAFAFA',
    },
    secondary: {
      light: '#FFB703',
      main: '#FB8500',
      dark: '#FB8500',
      contrastText: '#124583',
    },
    terciary: {
      light: '#ff759e',
      main: '#e24070',
      dark: '#ab0045',
      contrastText: '#FAFAFA',
    },
    grey: {
      lighter: '#fafafa',
      light: '#f4f4f4',
      medium: '#eeeeee',
      main: '#cccccc',
      dark: '#bcbcbc',
      darker: '#707070',
      black: '#1e2023',
    },
    error: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#FAFAFA',
    },
    success: {
      light: '#66d568',
      main: '#2da33a',
      dark: '#007306',
      contrastText: '#FAFAFA',
    },
    warning: {
      light: '#ffd149',
      main: '#ffa000',
      dark: '#c67100',
      contrastText: '#fafafa',
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.22)',
      backgroundColor: 'rgba(0, 0, 0, 0.025)',
    },
  },
  typography: {
    fontFamily: 'Nunito',
    fontSize: 14,
    title: {
      color: '#707070',
      fontFamily: 'Nunito',
      lineHeight: '36',
      fontSize: '30',
      fontWeight: '500',
    },
    subheading: {
      color: '#707070',
      fontFamily: 'Nunito',
      lineHeight: '24',
      fontSize: '20',
      fontWeight: '500',
    },
    body: {
      color: '#707070',
      fontFamily: 'Nunito',
      lineHeight: '1.2',
      fontSize: '14',
      fontWeight: '400',
    },
    h1: {
      fontSize: '30px',
      fontWeight: '500',
    },
    h2: {
      fontSize: '20px',
      fontWeight: '500',
    },
    h3: {
      fontSize: '18px',
      fontWeight: '500',
    },
    h4: {
      fontSize: '16px',
      fontWeight: '500',
    },
    h5: {
      fontSize: '14px',
      fontWeight: '500',
    },
    h6: {
      fontSize: '13px',
      fontWeight: '500',
    },
  },
  background: {
    body: '#f4f4f4',
    pill: '#fafafa',
  },
  button: {
    textTransform: 'none',
    color: '#1B3D6E',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: '500',
    borderRadius: '5',
    border: '1',
    borderColor: '#1B3D6E66',
    lineHeight: 1.33,
    minWidth: '136px',
  },
  spacing: '1rem',
  shape: {
    borderRadius: 5,
  },
};
