import WelcomePage from './pages/WelcomePage';
import ActivationPage from './pages/ActivationPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import FormResetPasswordPage from './pages/FormResetPasswordPage';

const privateRoutes = [];

const publicRoutes = [
  {
    id: 'HOME_PAGE',
    path: '/',
    exact: true,
    component: WelcomePage,
  },
  {
    id: 'ACTIVATION_PAGE',
    path: '/activation/:token',
    exact: true,
    component: ActivationPage,
  },
  {
    id: 'SEND_RESET_PASSWORD_PAGE',
    path: '/reset',
    exact: true,
    component: ResetPasswordPage,
  },
  {
    id: 'RESET_PASSWORD_PAGE',
    path: '/reset-password/:token',
    exact: true,
    component: FormResetPasswordPage,
  },
];

export { privateRoutes, publicRoutes };
