import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';

// import rootReducer from './reducers';
import rootSaga from './sagas';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, logger];

  return {
    ...createStore(() => {}, applyMiddleware(...middleware)),
    runSaga: sagaMiddleware.run(rootSaga),
  };
};

export default configureStore;
