import styled from '@emotion/styled';
import { Grid } from '@shopadvizor/tarot';

export const GridContainer = styled.div`
  padding: 0 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-left: -40px;
  margin-right: -40px;
  @media (max-width: 600px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const GridItem = styled(Grid)`
  padding: 0 40px;
  width: 100%;
  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
