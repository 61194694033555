const user = {
  'user.email': 'Correo electrónico',
  'user.name': 'Nombre',
  'user.lastName': 'Apellidos',
  'user.password': 'Contraseña',
  'user.repeatPassword': 'Confirme contraseña',
  'user.signIn': 'Registrarse',
  'user.email.errorText': 'Este email ya existe',
  'user.email.helperText': 'Ej: mail@exapmple.com',
  'user.string.helperText': 'Solo se permiten letras, hasta un máximo de {maxLength}',
  'user.password.helperText': 'Al menos 8 carácteres. Debe incluir al menos un número, una letra mayúscula y otra minúscula',
  'user.repeatPassword.helperText': 'Debe coincidir con la contraseña',
  'user.acceptConditions.helperText': 'Por favor, antes de continuar acepta las condiciones',
  'user.activation.success': 'Tu cuenta ha sido activada con éxito',
  'user.activation.fail': 'Lo sentimos, hubo problema en la activación, inténtalo más tarde',
  'user.field.mandatory': 'Este campo es obligatorio',
  'user.sendResetPassword.title': 'Por favor, escribe tu email para poder resetear tu contraseña',
  'user.reactivation.button': 'Reestablecer',
  'user.send.email.susscess': 'Te enviaremos un correo electrónico para reestablecer tu contraseña, por favor, revisa tu email',
  'user.email.helper': 'ej: email@shopadvizor.com',
  'user.resetPassword.title': 'Por favor, elije una nueva contraseña',
  'user.password.label': 'Contraseña',
  'user.reset.password.susscess': 'Tu contraseña ha sido reestablecida, por favor, inicia sesión',
  'user.reset.password.fail': 'Lo sentimos, ha habido un problema con tu email, por favor, inténtalo más tarde',
  'user.send.email.fail': 'Lo sentimos, ha habido un problema al intentar resetar la contraseña, inténtalo más tarde',
};

export default user;
