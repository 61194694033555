import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Intl from './hoc/Intl/Intl';
import App from './App';

function Root() {
  return (
    <Intl>
      <Router>
        <App />
      </Router>
    </Intl>
  );
}

export default Root;
