const user = {
  'user.email': 'Adresse e-mail',
  'user.name': 'Prénom',
  'user.lastName': 'Nom',
  'user.password': 'Mot de passe',
  'user.repeatPassword': 'Confirmez votre mot de passe',
  'user.signIn': 'S’inscrire',
  'user.email.errorText': 'Cet e-mail existe déjà',
  'user.email.helperText': 'Ej: mail@example.com',
  'user.string.helperText': 'Veuillez saisir uniquement des lettres avec un maximum de {maxLength}',
  'user.password.helperText': 'Votre mot de passe doit contenir : Plus de 8 caractères, au moins une minuscule et une majuscule',
  'user.repeatPassword.helperText': 'Au moins un chiffre',
  'user.acceptConditions.helperText': 'Avant de continuer, veuillez accepter les conditions.',
  'user.activation.success': 'Votre compte est désormais actif.',
  'user.activation.fail': 'Nous sommes désolés, il y a eu un problème d’activation, veuillez essayez ultérieurement.',
  'user.field.mandatory': 'Ce champ est obligatoire',
  'user.sendResetPassword.title': 'Ecrivez votre adresse e-mail, s’il vous plait, pour rétablir votre mot de passe',
  'user.reactivation.button': 'Rétablir',
  'user.send.email.susscess': 'Nous vous avons envoyé un e-mail pour rétablir votre mot de passe, veuillez vérifier votre boîte mail',
  'user.email.helper': 'mail@example.com',
  'user.resetPassword.title': 'Choisissez un nouveau mot de passe',
  'user.password.label': 'Mot de passe',
  'user.reset.password.susscess': 'Nous avons rétabli votre mot de passe, vous pouvez vous connecter sur votre compte',
  'user.reset.password.fail': 'Nous sommes désolés, il y a eu un problème avec votre e-mail, veuillez, s’il vous plait, essayer ultérieurement',
  'user.activation.title': 'Votre compte ShopAdvizor est en cours d\'activation',
  'user.confirmPassword.label': 'Confirmer le mot de passe.',
  'user.send.email.fail': 'Nous sommes désolés, il y a eu un problème avec votre e-mail, veuillez, s\'il vous plaît, essayer ultérieurement.'
};

export default user;
