import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Typography,
} from '@shopadvizor/tarot';
import ResetPasswordContainer from '../../components/users/ResetPassword/ResetPasswordContainer';

const FormResetPasswordPage = () => (
  <Container maxWidth="md">
    <Typography
      variant="h2"
      component="h1"
      color="primary"
      align="center"
      spacingY="s1"
      id="reset-password-title"
    >
      <FormattedMessage
        id="user.sendResetPassword.title"
        defaultMessage="Please, write your email to reset your password"
      />
    </Typography>
    <ResetPasswordContainer />
  </Container>
);
export default FormResetPasswordPage;
