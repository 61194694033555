import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  DialogState, Typography, Loading,
} from '@shopadvizor/tarot';
import { FormattedMessage } from 'react-intl';
import FormResetPassword from './FormResetPassword';

const RESET_PASSWORD = gql`
  mutation resetPassword ($token: String!, $password: String!){
  resetPassword(
      token: $token
      password: $password
  )
}`;

const CHECK_TOKEN = gql`
  mutation TokenExpire ($token: String!) {
  expireToken(token: $token)
}`;

const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';
const TOKEN_IS_VALID = 'TOKEN_IS_VALID';
const TOKEN_ERROR = 'TOKEN_ERROR';

const ResetPasswordPage = (props) => {
  const { match: { params } } = props;
  const { token } = params;
  const [result, setResult] = useState(undefined);
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const [expireToken, { loading: checkingTokenLoading }] = useMutation(CHECK_TOKEN);

  useEffect(() => {
    async function fetchData() {
      expireToken({
        variables: {
          token,
        },
      }).then(() => setResult(TOKEN_IS_VALID))
        .catch(() => {
          setResult(TOKEN_ERROR);
        });
    }
    fetchData();
  }, [expireToken, token]);

  if (loading || checkingTokenLoading) {
    return <Loading />;
  }

  if (result === TOKEN_IS_VALID) {
    return (
      <FormResetPassword onSubmit={(fields) => {
        resetPassword({
          variables: {
            password: fields.password.value,
            token: params.token,
          },
        })
          .then(() => setResult(SUCCESS))
          .catch(() => setResult(ERROR));
      }}
      />
    );
  }

  if (result === SUCCESS || result === ERROR || result === TOKEN_ERROR) {
    const variant = (result === SUCCESS) ? 'success' : 'error';
    return (
      <DialogState variant={variant}>
        <Typography variant="h2" component="h1" align="center" spacingY="s1">
          {result === SUCCESS ? (
            <FormattedMessage
              id="user.reset.password.susscess"
              defaultMessage="Your password has been reset, please, login."
            />
          ) : (
            <FormattedMessage
              id="user.reset.password.fail"
              defaultMessage="Sorry, there was a problem with your email, please wait and try it later"
            />
          )}
        </Typography>
      </DialogState>
    );
  }

  return null;
};

export default ResetPasswordPage;
