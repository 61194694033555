const user = {
  'user.email': 'Email',
  'user.name': 'Name',
  'user.lastName': 'Last name',
  'user.password': 'Password',
  'user.repeatPassword': 'Confirm password',
  'user.signIn': 'Sign in',
  'user.email.errorText': 'This email already exits',
  'user.email.helperText': 'Ej: mail@exapmple.com',
  'user.string.helperText': 'Just numbers and letters max: {maxLength}',
  'user.password.helperText': 'At least 8 chars. It should include uppercase, lowercase and numbers',
  'user.repeatPassword.helperText': 'Password should match',
  'user.acceptConditions.helperText': 'Please, accept conditions',
  'user.activation.success': 'Your account has been activated',
  'user.activation.fail': 'Sorry, there was a problem trying to activate your account',
  'user.field.mandatory': 'This field cannot be empty',
  'user.sendResetPassword.title': 'Please, write your email to reset the password',
  'user.reactivation.button': 'Reset',
  'user.send.email.susscess': 'We will send you an email to reset your password. Please, check your email',
  'user.email.helper': 'ej: email@shopadvizor.com',
  'user.resetPassword.title': 'Please, choose a new password',
  'user.password.label': 'Password',
  'user.reset.password.susscess': 'The password has been reset succesfully',
  'user.reset.password.fail': 'Sorry, there was a problem with your email, please try it later',
};

export default user;
