import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Typography,
} from '@shopadvizor/tarot';
import FormResetPasswordContainer from '../../components/users/FormResetPassword/FormResetPasswordContainer';

const FormResetPasswordPage = props => (
  <Container maxWidth="md">
    <Typography
      variant="h2"
      component="h1"
      color="primary"
      align="center"
      spacingY="s1"
      id="reset-password-title"
    >
      <FormattedMessage
        id="user.resetPassword.title"
        defaultMessage="Please, choose a new password"
      />
    </Typography>
    <FormResetPasswordContainer {...props} />

  </Container>
);
export default FormResetPasswordPage;
