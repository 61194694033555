import React, { Component } from 'react';
import {
  Grid, FormGroupPassword, ButtonWithLoading,
} from '@shopadvizor/tarot';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import FormControlled from '../../../hoc/Form/FormControlled';
import { COMMON_FIELDS } from '../../../hoc/Form/formConstants';
import { GridContainer, GridItem } from './styledComponent';

import './FormatResetPassword.scss';

const MIN_PASSWORD_LENGTH = 5;

const isButtonDisabled = (fields) => {
  const {
    confirmPassword, password,
  } = fields;
  let invalidForm = true;

  if (confirmPassword.error === false && password.value.length >= MIN_PASSWORD_LENGTH) {
    invalidForm = false;
  }

  return invalidForm;
};

const createForm = formattedMessage => ({
  _handleOnChange, _handleOnSubmit, _isButtonDisabled, _isButtonLoading, fields,
}) => (
  <form onSubmit={_handleOnSubmit} className="formResetPassword">
    <GridContainer>
      <GridItem item sm={12} md={12}>
        <FormGroupPassword
          id="password"
          label={formattedMessage({
            id: 'user.password.label',
            defaultMessage: 'Password',
          })}
          fullWidth
          onChange={event => _handleOnChange(
            event, COMMON_FIELDS.password,
            event.target.value,
          )}
          value={fields.password.value}
          error={fields.password.error}
          helperText={formattedMessage({
            id: 'user.password.helperText',
            defaultMessage: 'Al menos 8 carácteres alfanuméricos, una letra mayúscula, una minúscula y un número',
          })}
        />
        <FormGroupPassword
          id="confirmPassword"
          label={formattedMessage({
            id: 'user.confirmPassword.label',
            defaultMessage: 'Confirm password',
          })}
          fullWidth
          name="password"
          onChange={event => _handleOnChange(
            event,
            COMMON_FIELDS.confirmPassword,
            event.target.value,
          )}
          value={fields.confirmPassword.value}
          error={fields.confirmPassword.error}
          helperText={fields.confirmPassword.error ? formattedMessage({
            id: 'user.password.helperText',
            defaultMessage: 'Both passwords must be equal',
          }) : null}
        />
      </GridItem>
    </GridContainer>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <ButtonWithLoading variant="contained" color="primary" type="submit" disabled={_isButtonDisabled()} loading={_isButtonLoading()}>
        <FormattedMessage
          id="user.reactivation.button"
          defaultMessage="Reestablecer"
        />
      </ButtonWithLoading>
    </Grid>
  </form>
);

class FormResetPassword extends Component {
  state = {
    confirmPassword: { value: '', error: undefined },
    password: { value: '', error: undefined },
  };

  render() {
    const { onSubmit, isLoading, intl: { formatMessage } } = this.props;

    const Form = FormControlled(createForm(formatMessage), this.state);
    return (
      <Form
        handleSubmit={onSubmit}
        isButtonDisabled={isButtonDisabled}
        isLoading={isLoading}
      />
    );
  }
}

FormResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default injectIntl(FormResetPassword);
