import React from 'react';
import { ThemeWrapper, BrandLogo } from '@shopadvizor/tarot';

import styled from '@emotion/styled';

import theme from '../localResources/theme';
import { ReactComponent as LogoSVG } from '../localResources/logo.svg';


const LayoutPublicWrapper = styled.div`
  min-height: 100vh;
  background-color: #fff;
  padding-top: 2em;
`;

export const LayoutPrivate = (props) => {
  const { children } = props;
  return (
    <div>
      <header>
        NAV
      </header>
      <main>
        {children}
      </main>
    </div>
  );
};

export const LayoutPublic = (props) => {
  const { children } = props;
  return (
    <LayoutPublicWrapper>
      <header>
        <BrandLogo id="brand-logo-nav" align="center" spacingY="l2">
          <LogoSVG />
        </BrandLogo>
      </header>
      <main>
        {children}
      </main>
    </LayoutPublicWrapper>
  );
};

const Layout = (props) => {
  const { authenticated } = props;
  const { isAuthenticated } = authenticated;
  return (
    <ThemeWrapper theme={theme}>
      {isAuthenticated && <LayoutPrivate {...props} />}
      {!isAuthenticated && <LayoutPublic {...props} /> }
    </ThemeWrapper>
  );
};

export default Layout;
