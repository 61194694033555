import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { privateRoutes, publicRoutes } from './Routes';
import Layout from './hoc/Layout/Layout';
import { checkToken } from './utils/localStorageAuth';
import getApiPath from './utils/getApiPath';

const client = new ApolloClient({
  uri: getApiPath(),
});

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      checkToken() !== null ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      )
    )}
  />
);

const App = (isAuthenticated, isVerifying) => (
  <ApolloProvider client={client}>
    <Layout authenticated={isAuthenticated}>
      <Switch>
        {privateRoutes.map(route => (
          <ProtectedRoute
            key={route.id}
            exact={route.exact}
            path={route.path}
            component={route.component}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
        ))}

        {publicRoutes.map(route => (
          <Route
            key={route.id}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
    </Layout>
  </ApolloProvider>

);

function mapStateToProps() {
  return {
    /* isAuthenticated: checkToken() !== false, */
    isAuthenticated: false,
    isVerifying: true,
  };
}

export default connect(mapStateToProps)(App);
