const getApiPath = () => {
  const INDEX_OF_VALUE = -1;
  const PRODUCTION_HOST = 'public.shopadvizor.com';
  const PRE_HOST = 'prepro-public.shopadvizor.net';

  const GRAPHQL_API_BASE_URL_PRODUCTION = 'https://graphql-gateway.public.shopadvizor.com';
  const GRAPHQL_API_BASE_URL_PRE = 'https://graphql-gateway.prepro-public.shopadvizor.net';
  const GRAPHQL_API_BASE_URL_DEV = 'https://graphql-gateway.develop-public.shopadvizor.net';

  if (window.location.host.indexOf(PRODUCTION_HOST) !== INDEX_OF_VALUE) {
    return GRAPHQL_API_BASE_URL_PRODUCTION;
  }
  if (window.location.host.indexOf(PRE_HOST) !== INDEX_OF_VALUE) {
    return GRAPHQL_API_BASE_URL_PRE;
  }

  return GRAPHQL_API_BASE_URL_DEV;
};

export default getApiPath;
