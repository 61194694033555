import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import moment from 'moment';

// Import all Translations messages.
import messagesEn from '../../platform/locales/en';
import messagesEs from '../../platform/locales/es';
import messagesFr from '../../platform/locales/fr';

// const clientLocale = navigator.language.split(/[-_]/)[0];
const clientLocale = 'fr';

moment.locale(clientLocale);

class Intl extends Component {
  defaultLocale = 'fr';

  locales = null;

  messages = null;

  constructor() {
    super();
    this.locales = {
      en: { locale: this.defaultLocale, messages: messagesEn },
      es: { locale: this.defaultLocale, messages: messagesEs },
      fr: { locale: this.defaultLocale, messages: messagesFr },
    };
  }

  validLocale(locale) {
    return (typeof this.locales[locale] === 'undefined')
      ? this.defaultLocale
      : locale;
  }

  render() {
    const { children } = this.props;

    const validLocale = this.validLocale(clientLocale);

    const props = {
      locale: validLocale,
      messages: this.locales[validLocale].messages,
    };

    return (
      <IntlProvider locale={props.locale} messages={props.messages}>
        <div>
          {children}
        </div>
      </IntlProvider>
    );
  }
}

Intl.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Intl;
