import React, { Component } from 'react';
import {
  checkPassword,
  checkEmail,
  checkConfirmPassword,
  checkString,
} from './formUtils';
import { COMMON_FIELDS, FIELDS_TYPE } from './formConstants';

const FormControlled = (Form, initialState) => (
  class FormWithControlled extends Component {
    constructor(props) {
      super(props);

      this.state = {
        ...initialState,
      };
    }

    handleOnChange = (event, name, value, type, saveWithoutCheck) => {
      if (saveWithoutCheck) {
        this.setState({ [name]: { value } });
      } else if (name === COMMON_FIELDS.email) {
        this.setState({ [name]: { value, ...checkEmail(value) } });
      } else if (name === COMMON_FIELDS.password) {
        this.setState({ [name]: { value, ...checkPassword(value) } });
      } else if (name === COMMON_FIELDS.confirmPassword) {
        const { password } = this.state;
        this.setState({ [name]: { value, ...checkConfirmPassword(value, password.value) } });
      } else if (type === FIELDS_TYPE.checkbox) {
        this.setState({ [name]: { value, isValid: value } });
      } else {
        this.setState({ [name]: { value } });
      }
    };

    handleOnBlur = (event, name, value, type, conditions) => {
      if (type === FIELDS_TYPE.text) {
        this.setState({ [name]: { value, ...checkString(value, conditions) } });
      }
      if (name === COMMON_FIELDS.email) {
        this.setState({ [name]: { value, ...checkEmail(value) } });
      }
    };

    handleOnSubmit = (e) => {
      const { handleSubmit } = this.props;
      e.preventDefault();

      handleSubmit(this.state);
    };


    isButtonDisabled() {
      const { isButtonDisabled } = this.props;

      return isButtonDisabled(this.state);
    }

    isButtonLoading() {
      const { isLoading } = this.props;

      return isLoading;
    }


    render() {
      return (
        <Form
          {...this.props}
          fields={this.state}
          _isButtonDisabled={() => this.isButtonDisabled()}
          _handleOnSubmit={this.handleOnSubmit}
          _handleOnChange={this.handleOnChange}
          _handleOnBlur={this.handleOnBlur}
          _isButtonLoading={() => this.isButtonLoading()}
        />
      );
    }
  }
);

export default FormControlled;
