import React, { Component } from 'react';
import {
  Grid, TextField, ButtonWithLoading,
} from '@shopadvizor/tarot';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import FormControlled from '../../../hoc/Form/FormControlled';
import { COMMON_FIELDS } from '../../../hoc/Form/formConstants';
import { GridContainer, GridItem } from './styledComponents';

const EMAIL_MAX_LENGTH = 254;

const isButtonDisabled = (fields) => {
  const {
    email,
  } = fields;
  let invalidForm = true;

  if (email.error === false) {
    invalidForm = false;
  }

  return invalidForm;
};

const createForm = formattedMessage => ({
  _handleOnChange, _handleOnBlur, _handleOnSubmit, _isButtonDisabled, _isButtonLoading, fields,
}) => (
  <form onSubmit={_handleOnSubmit}>
    <GridContainer>
      <GridItem item sm={12} md={12}>
        <TextField
          id="email"
          label={formattedMessage({
            id: 'user.email',
            defaultMessage: 'Email',
          })}
          fullWidth
          onChange={event => _handleOnChange(event, COMMON_FIELDS.email, event.target.value)}
          onBlur={event => _handleOnBlur(event, COMMON_FIELDS.email, event.target.value)}
          value={fields.email.value}
          error={fields.email.error}
          helperText={fields.email.error ? formattedMessage({
            id: 'user.email.helper',
            defaultMessage: 'Ex: email@shopadvizor.com',
          }) : null}
          inputProps={{
            type: 'email',
            maxLength: EMAIL_MAX_LENGTH,
          }}
        />
      </GridItem>
    </GridContainer>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <ButtonWithLoading variant="contained" color="primary" type="submit" disabled={_isButtonDisabled()} loading={_isButtonLoading()}>
        <FormattedMessage
          id="user.reactivation.button"
          defaultMessage="Reset password"
        />
      </ButtonWithLoading>
    </Grid>
  </form>
);

class ResetPassword extends Component {
  state = {
    email: { value: '', error: undefined },
  };

  render() {
    const { onSubmit, isLoading, intl: { formatMessage } } = this.props;

    const Form = FormControlled(createForm(formatMessage), this.state);
    return (
      <Form
        handleSubmit={onSubmit}
        isButtonDisabled={isButtonDisabled}
        isLoading={isLoading}
      />
    );
  }
}

ResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default injectIntl(ResetPassword);
