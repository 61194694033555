import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Typography, DialogState, Loading } from '@shopadvizor/tarot';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';

const ACTIVATE_USER = gql`
  mutation activateUser($token: String!) {
    activateUser(token: $token)
  }
`;

const ActivateUser = (props) => {
  const {
    match: { params },
  } = props;
  const { token } = params;
  const [error, setError] = useState(null);
  const [onLoad, setOnLoad] = useState(true);
  const [activateUser, { loading }] = useMutation(ACTIVATE_USER);

  useEffect(() => {
    async function fetchData() {
      activateUser({
        variables: {
          token,
        },
      }).catch((err) => {
        const errors = err.graphQLErrors.map(e => e.message);
        setError(errors);
        setOnLoad(false);
      });
    }
    fetchData();
    setOnLoad(false);
  }, [activateUser, token]);
  // If the second argument is an empty array, only executing on the first rendering.

  if (onLoad || loading) return <Loading scale="l" />;
  if (error) {
    return (
      <DialogState
        alignContent="center"
        alignItems="center"
        align="center"
        color="error"
        minHeight="300px"
        spacingY="xl"
      >
        <ErrorIcon />
        <Typography variant="h2" component="h1" align="center" spacingY="s1" id="register-welcome-title">
          <FormattedMessage
            id="user.activation.fail"
            defaultMessage="Sorry, there was a problem activating your account, try it later"
          />
        </Typography>
      </DialogState>
    );
  }

  return (
    <DialogState
      alignContent="center"
      alignItems="center"
      align="center"
      color="success"
      minHeight="300px"
      spacingY="xl"
    >
      <DoneIcon />
      <Typography variant="h2" component="h1" align="center" spacingY="s1" id="register-welcome-title">
        <FormattedMessage
          id="user.activation.success"
          defaultMessage="Your account has been activated successfully"
        />
      </Typography>
    </DialogState>
  );
};

export {
  ACTIVATE_USER,
  ActivateUser,
};
