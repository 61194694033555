export function setToken(cname, cvalue) {
  window.localStorage.setItem(cname, cvalue);
}

export function checkToken() {
  const user = window.localStorage.getItem('token');
  if (user === '' || user === null) {
    return false;
  }
  return user;
}
