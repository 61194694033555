import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { DialogState, Typography, Loading } from '@shopadvizor/tarot';
import { FormattedMessage } from 'react-intl';
import ResetPassword from './ResetPassword';

const SEND_RESET_PASSWORD = gql`
  mutation sendResetPassword ($input : SendResetPasswordInput!){
  sendResetPassword(
    input: $input
  )
}`;

const SUCCESS = 1;
const ERROR = 0;

const SendResetPasswordPage = () => {
  const [result, setResult] = useState(undefined);
  const [sendResetPassword, { loading }] = useMutation(SEND_RESET_PASSWORD);

  if (loading) {
    return <Loading />;
  }

  if (result === undefined) {
    return (
      <ResetPassword onSubmit={(fields) => {
        sendResetPassword({ variables: { input: { username: fields.email.value } } })
          .then(() => setResult(SUCCESS))
          .catch(() => setResult(ERROR));
      }}
      />
    );
  }


  if (result === SUCCESS || result === ERROR) {
    const variant = (result === SUCCESS) ? 'success' : 'error';
    return (
      <DialogState variant={variant}>
        <Typography variant="h2" component="h1" align="center" spacingY="s1">
          {result === SUCCESS ? (
            <FormattedMessage
              id="user.send.email.susscess"
              defaultMessage="We will send you an email to reset your password, please, check your email"
            />
          ) : (
            <FormattedMessage
              id="user.send.email.fail"
              defaultMessage="Sorry, there was a problem with your email, please wait and try it later"
            />
          )}
        </Typography>
      </DialogState>
    );
  }


  return (
    <ResetPassword onSubmit={(fields) => {
      sendResetPassword({ variables: { username: fields.email.value } })
        .then(() => setResult(SUCCESS))
        .catch(() => setResult(ERROR));
    }}
    />
  );
};

export default SendResetPasswordPage;
