const EMAIL_MAX_LENGHT = 254;

const STRINGS_ARE_EQUAL_CODE = 0;

export const checkEmail = (value) => {
  const emailFormat = new RegExp('^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$');
  if (value.length >= EMAIL_MAX_LENGHT) {
    return { error: true, helperText: 'ERROR_MESSAGE_EMAIL_MAX_LENGHT' };
  } if (emailFormat.test(value.trim()) === false) {
    return { error: true, helperText: 'ERROR_MESSAGE_EMAIL_INVALID_FORMAT' };
  }
  return { error: false };
};

export const checkString = (value, conditions) => {
  const { maxLength, regex } = conditions;
  const stringFormat = new RegExp(/^[a-zA-ZA-zÀ-ú 'ñç\s]+$/);

  if (value.length > maxLength) {
    return { error: true, helperText: 'ERROR_MESSAGE_MAX_LENGTH' };
  } if (regex && regex.test(value) === false) {
    return { error: true, helperText: 'ERROR_MESSAGE_STRING_INVALID_FORMAT_1' };
  } if (stringFormat.test(value) === false) {
    return { error: true, helperText: 'ERROR_MESSAGE_STRING_INVALID_FORMAT_2' };
  }
  return { error: false };
};

export const checkPassword = (value) => {
  const passwordFormat = new RegExp('^(?=(.*\\d))(?=.*[a-z])(?=.*[A-Z]).{8,255}$');
  if (passwordFormat.test(value) === false) {
    return { error: true, helperText: 'ERROR_MESSAGE_PASSWORD_INVALID_FORMAT' };
  }
  return { error: false };
};

export const checkConfirmPassword = (value, password) => {
  if (value.localeCompare(password) !== STRINGS_ARE_EQUAL_CODE) {
    return { error: true, helperText: 'ERROR_MESSAGE_CONFIRM_PASSWORD_INVALID_FORMAT' };
  }
  return { error: false };
};
