import React from 'react';
import {
  Container, Typography, DialogState,
} from '@shopadvizor/tarot';
import { FormattedMessage } from 'react-intl';

const WelcomePage = () => (
  <Container maxWidth="md">
    <DialogState color="primary" spacingY="xl">
      <Typography variant="h1" component="h1" align="center" spacingY="s1" id="welcome-title">
        <FormattedMessage
          id="app.welcome.title"
          defaultMessage="Wellcome to ShopAdvizor"
        />
      </Typography>
    </DialogState>
  </Container>
);

export default WelcomePage;
