export const EMAIL_MAX_LENGTH = 254;


export const COMMON_FIELDS = {
  email: 'email',
  confirmPassword: 'confirmPassword',
  password: 'password',
};

export const FIELDS_TYPE = {
  text: 'text',
  checkbox: 'checkbox',
};
