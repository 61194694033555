const promotion = {
  'promotion.promoStatsTitle': 'Promotion and Benefits',
  'promotion.listHeading': 'Promotions List',
  'promotion.activeCountlabel': 'Number of promos active : ',
  'promotion.inactiveCountLabel': 'Number of promos inactive : ',
  'promotion.totalPromoLabel': 'Total promos : ',
  'promotion.addNewPromo': 'Add New Promo',
  'promotion.save': 'Save',
  'promotion.errorInServer': 'Some Error In Server',
  'promotion.noPromos': 'No Promotion Added yet',
  'promotion.noResults': 'Sorry, we can’t find any results for your search',
  'promotion.tableKey': 'Key',
  'promotion.promoKey': 'Promotion key {promoKey}',
  'promotion.tablePromoName': 'Promotion Name',
  'promotion.tableStartDate': 'Start Date',
  'promotion.tableStatus': 'Status',
  'promotion.promoType': 'Promo type',
  'promotion.promoName': 'Promo name',
  'promotion.promoDescription': 'Description',
  'promotion.promoLegalPolicies': 'Legal policies',
  'promotion.promoCouponCode': 'Coupon code',
  'promotion.startDate': 'Start date',
  'promotion.endDate': 'End date',
  'promotion.startDateError': 'The date must at least one day before',
  'promotion.endDateError': 'The date must at least one day after',
  'promotion.defaultDateError': 'The date must be correct, please try again',
  'promotion.mandatoryFieldError': 'This field is mandatory',
  'promotion.promoStatusLabel': 'Status',
  'promotion.ActiveStatus': 'Active',
  'promotion.InActiveStatus': 'Inactive',
  'promotion.editContent': 'Edit content',
  'promotion.exitWithoutSave': 'Exit without save',
  'promotion.responseError': '{errorCode} : {message}',
  'promotion.searchPlaceHolder': 'Search by promotion name',
  'promotion.dropzonePlaceholder': 'Drag and drop or click here to upload an image The image must be a JPG or PNG with a maximum weight of 2 MB and a maximum size of 720x384 px',
  'promotion.fileWeightError': 'The image that you are trying to upload exceeds the maximum weight of 2MB. Please reduce the image and try again',
  'promotion.fileSizeError': 'The image that you are trying to upload exceeds the maximum size of 720x384 px. Please reduce the image and try again',
  'promotion.combinedFileError': 'The image that you are trying to upload exceeds the maximum weight of 2MB or the maximum size of 720x384 px Please reduce the image and try again',
  'promotion.fileDropError': 'File type not supported.',
  'promotion.promoFilterBy': 'Filter by',
  'promotion.promoStartedBetween': 'Promotions started between',
  'promotion.fromDateError': 'The date must be before',
  'promotion.untilDateError': 'The date must be later',
  'promotion.filterDefaultDateError': 'The date must be correct, please try again',
};

export default promotion;
