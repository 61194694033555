import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Typography,
} from '@shopadvizor/tarot';
import { ActivateUser } from '../../components/users/ActivateUser/ActivateUser';

const ActivationPage = props => (
  <Container maxWidth="md">
    <Typography
      variant="h2"
      component="h1"
      color="primary"
      align="center"
      spacingY="s1"
      id="register-welcome-title"
    >
      <FormattedMessage
        id="user.activation.title"
        defaultMessage="Activating your user in ShopAdvizor"
      />
    </Typography>
    <ActivateUser {...props} />
  </Container>
);

export default ActivationPage;
